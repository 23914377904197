export default {

   
    //shop
    shopTitle: "Магазин",
    shopDescription: "Присоединиться к компании",


    //products
    companyIdPaceholder: "Трехзначный код компании",
    connectToCompany: "Присоединиться к компании",

    //productCard
    productCardFrom: "от",
    productColor: "Цвет",
    productParameters: "Параметры",
    width: "Ширина, см",
    height: "Высота, см",
    depth: "Глубина, см",
    weight: "Масса, кг",
    material: "Материал",
    recyclableMaterials: "Доля вторичных материалов, %",
    productAddToCart: "Добавить в корзину",

    //cart
    removeFromCart: "Убрать",
    cart: "Корзина",
    total: "Итого",
    payEcoin: "Оплатить Экойнами",
    payMoney: "Оплатить рублями",
    connectToCompany: "Присоединиться к компании",
    delivery: "Доставка",
    nameField: "Имя и фамилия",
    emailField: "Email",
    phoneField: "Телефон",
    addressField: "Адрес доставки",
    commentField: "Комментарий",
    policyField: "Соглашаюсь на обработку персональных данных и с политикой конфиденциальности магазина",
    paymentMethodField: "Способ оплаты",
    paymentMethodCardField: "оплата картой",
    paymentMethodWireField: "оплата по счету (ЮЛ)",
    completeOrder: "Подвердить заказ",
    orderDialogTitle: "Спасибо за заказ!",
    orderDialogText: "Ваш заказ принят. В ближайшее время наши менеджеры свяжутся с Вами для уточнения деталей по заказу",
    orderDialogButton: "Хорошо",

    orderAlertDialogTitle: "Внимание",
    orderAlertDialogText: "Не все обязательные поля заполены",
    orderAlertDialogButton: "Хорошо",


    //promo
    companyIdPaceholder: "Трехзначный код компании",
    connectToCompany: "Присоединиться к компании",

  
  }