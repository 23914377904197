import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {reo1Color} from "./../Constants";
import {useTranslation} from 'react-i18next';
import { useNavigate } from "react-router-dom";

export const ProductCard = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
    <Card sx={{ maxWidth: 1000,  cursor: 'pointer' }} onClick={() => {navigate('/product', { state: { id: props.id } })}}>
        <Box sx={{ position: 'relative' }}>
        <CardMedia
            component="img"
            alt={props.productName}
            height="200"
            image={props.images[0]}
        />
        <Box
            sx={{
                position: 'absolute',
                top: 150,
                right: 5,
                width: '100%',
                alignItems: 'flex-end',
                textAlign: 'right',
                color: 'black',
                padding: '10px',
            }}
            >
            <Typography variant="h5"  color="white" >{props.productPrice}₽</Typography>
        </Box>
        <CardContent>
            <Typography variant="h5" sx={{lineHeight: "1em", height: "50px"}}>
                {props.productName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {t('productCardFrom')} {props.companyName}
            </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}