import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {useTranslation} from 'react-i18next';
import { ProductCard } from "../components/ProductCard.component";
import { PromoCard } from "../components/PromoCard.component";
import Grid from '@mui/material/Grid';
import {products} from '../assets/data/products';
import {promo} from '../assets/data/promo';
import { CartIcon } from "../components/CartIcon.component";
import {
  cartStorage,
  ecoinBalanseStorage,
  userIdStorage,
  nameStorage,
  emailStorage,
  phoneStorage
} from './../Constants';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

export const Shop = (props) => {
  const {t} = useTranslation();
  const [cart, setCart] = useState([]);
  const [productsSorted, setProductsSorted] = useState([]);
  const [promoSorted, setPromoSorted] = useState([]);
  const location = useLocation();

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    params.get('userId') != null &&  localStorage.setItem(userIdStorage, params.get('userId'));
    params.get('ecoinBalance') != null &&  localStorage.setItem(ecoinBalanseStorage, params.get('ecoinBalance'));
    params.get('name') != null &&  localStorage.setItem(nameStorage, params.get('name'));
    params.get('email') != null &&  localStorage.setItem(emailStorage, params.get('email'));
    params.get('phone') != null &&  localStorage.setItem(phoneStorage, params.get('phone'));
    //console.log(params.get('ecoinBalance'))
    let cart = localStorage.getItem(cartStorage);
    //console.log(JSON.parse(cart))
    try{
      const cartJSON = JSON.parse(cart);
      setCart(cartJSON);
    }
    catch
    {
      //console.log("Cart data error");
    }

    let sortedData =  products.sort((a, b) => b.priority-a.priority);
    setProductsSorted(sortedData);

    let sortedPromo =  promo.sort((a, b) => b.priority-a.priority);
    setPromoSorted(sortedPromo);

  },[])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('shopTitle')}</title>
      </Helmet>
      <Grid m={{xs:1, sm:2, md:5, lg: 10}}>
      {/* <PromoCard promo={promoSorted}/> */}
      <Grid container spacing={2}>
        {productsSorted.length > 1
        ? <>
        {productsSorted.map((item) => 
            <Grid xs={6} md={3} key={item.id} item>
                <ProductCard 
                   id={item.id}
                   productName={item.productName}
                   companyName={item.companyName}
                   companyLogo={item.companyLogo}
                   images={item.images}
                   productPrice={item.productPrice}
                   productDescription={item.productDescription}
                   productParameters={item.productParameters}
                />
              </Grid>  
        )}
        </>
        :<></>}
      </Grid>
      </Grid>
      <Box 
        ml={3}
        sx={{
          position: "sticky",
          bottom: "20px"
        }}>
        <CartIcon 
          cart={cart}
          />
      </Box>
    </HelmetProvider>
  );
};
