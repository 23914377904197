import * as React from 'react';
import { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next';

export const CartIcon = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [productCount, setProductCount] = useState(0);

    useEffect(() =>{
        try{
            let val = props.cart.length;
            setProductCount(val)
        }
        catch
        {
            //console.log("Cart parse error")
        }
    },[props])

    return (
        <>
        {productCount >= 0
        ? <Badge color="secondary" badgeContent={productCount} >
            <Box 
                onClick={() => {productCount> 0 ? navigate('/cart'): console.log("No products in cart")}}
                sx={{
                    width: 60,
                    height: 60,
                    borderRadius: 6,
                    backgroundColor: "green",
                    alignContent: "center",
                    cursor: 'pointer' 
                }}
                display="flex"
                justifyContent="center"
                >
                <Box mt={2}>
                <ShoppingCartIcon mt={10} sx={{color: "white"}}/>
                </Box>
            </Box>
            </Badge>
        :<></>}
        </>
  );
}