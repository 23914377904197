import React from "react";
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import ru from './locales/ru';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: 'ru',
  fallbackLng: 'ru',
  resources:{
    ru:{
      translation: ru,
    },
  },
  interpolation: {
    escapeValue: false 
  },
  react: {
    useSuspense:false,
 }
});

export default i18n;