
import { useEffect ,useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {products} from '../assets/data/products';
import Grid from '@mui/material/Grid';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Radio from '@mui/material/Radio';
import {ColorPicker} from '../components/ColorPicker.component'
import Button from '@mui/material/Button';
import {cartStorage, blueColor } from './../Constants';
import { useNavigate } from "react-router-dom";

export const Product = (props) => {

  const { state } = useLocation();
  const { id } = state || {};
  const [ product, setProduct ] = useState(null);
  const [ color, setColor] = useState(null);
  const {t} = useTranslation();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const navigate = useNavigate();

  useEffect(() =>{
    setProduct(products.filter((val) => val.id == id)[0]);
  }, [])


  const addToCart = () =>{
    let cart = localStorage.getItem(cartStorage);
    let productJSON = product;
    productJSON.color = color;
    productJSON.cartId = Math.floor(Math.random() * 100000);;
    try{
      const cartJSON = JSON.parse(cart);
      //console.log(cartJSON.toString())
      cartJSON.push(productJSON)
      //console.log(JSON.stringify(cartJSON))
      localStorage.setItem(cartStorage, JSON.stringify(cartJSON));
      navigate('/');
    }
    catch
    {
      //console.log("Cart data error");
      let cartJSON = [productJSON];
      //console.log(JSON.stringify(cartJSON));
      localStorage.setItem(cartStorage, JSON.stringify(cartJSON));
      navigate('/');
    }
  }

  const setSelectedColor = (color) =>{
    //console.log(color);
    setColor(color);
  }

  return (
    <>
    {product != null
    ? <Grid container p={{xs:1, sm:2, md:5}} item>
        <Grid item xs={12} md={4} sx={{ alignContent: 'center' }} alignItems="center" p={2} > 
            <Carousel axis="vertical" showStatus={false} showArrows={true} >
              {product.images.map(item =>
                  <div key={item}>
                      <img src={item} />
                  </div>
              )}
              </Carousel>
        </Grid>

        <Grid xs={12} md={8} p={2}  item>

          <Grid  xs={12} md={12} container item>
            <Grid xs={9} md={9}  item>  
              <Typography variant="h4" mt={1}>
                {product.productName}
              </Typography>
            </Grid>
            <Grid xs={3} md={3} item> 
              <img src={product.companyLogo}  width={windowSize.current[1]*0.15}  alt='Logo' />
            </Grid>
          </Grid>
        
          <Grid xs={12} md={12} item>
            <Typography variant="h4" color={blueColor}>
              {product.productPrice}₽
            </Typography>
          </Grid>

          <Grid  xs={12} md={12} mt={2} item>
            <Typography variant="subtitle" >
              {t('productColor')}
            </Typography>
            <ColorPicker colors={product.colors} setSelectedColor={(color) => setSelectedColor(color) }/>
          </Grid>
        
          <Grid  xs={12} md={12} mt={4} item>
            <Typography variant="subtitle" sx={{color: "#EOF400"}}>
              {product.productDescription}
            </Typography>
          </Grid>

          <Grid container xs={12} md={12} mt={4} item> 
            <Typography variant="subtitle" >
              {t('productParameters')}
            </Typography>
            {Object.entries(product.productParameters).map(([key, value]) => {
                return (
                  <Grid item key={key} container xs={12} md={12} mt={1}> 
                    <Grid  xs={10} md={10} item>
                      <Typography variant="subtitle" gutterBottom>{t(key)}</Typography>
                    </Grid> 
                    <Grid  xs={2} md={2} item> 
                      <Typography variant="subtitle" gutterBottom>{value}</Typography>
                    </Grid>
                  </Grid>
                )
            })}
          </Grid>
        
          <Grid xs={12} md={12} mt={4} item> 
              <Button variant="contained" color="reoBlue" onClick={() => addToCart()} fullWidth>{t("productAddToCart")}</Button>
          </Grid>
        </Grid>
    </Grid>
    : <></>}
    </>
  );
};
