export const products = [
    {
        "id": 1,
        "priority" : 50,
        "productName": "Дверной номерок",
        "companyName": "TrashBack",
        "companyLogo": "https://play-lh.googleusercontent.com/rotgXqsv6k8LQabVgQgSH6k4XY7Atk_Cx7iOPKF6S4DU1mBi4ibY28FN_InzsmSLLvA=w600-h300-pc0xffffff-pd",
        "images": [
            "https://i.ibb.co/5j4RDFh/number2.jpg",
            "https://i.ibb.co/zstQktX/number3.jpg",
            "https://i.ibb.co/jTV8mm8/numer1.jpg"
        ],
        "productPrice" : 800,
        "productDescription" : "Дверной номерок, напечатанный с помощью крупноформатного принтера из вторичного пластика. Поверхность глянцевая. ",
        "productParameters": {
            "weight" : 0.1,
            "height" : 10,
            "width" : 15,
            "depth": 1,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#2DBE64",
            "#D7144B"
        ]
    },
    {
        "id": 2,
        "priority" : 49,
        "productName": "Корзина",
        "companyName": "TrashBack",
        "companyLogo": "https://play-lh.googleusercontent.com/rotgXqsv6k8LQabVgQgSH6k4XY7Atk_Cx7iOPKF6S4DU1mBi4ibY28FN_InzsmSLLvA=w600-h300-pc0xffffff-pd",
        "images": [
                "https://i.ibb.co/Hd2RJmg/43e87583-99ce-4dfe-bca1-d9c8a750cbb3.jpg",
                "https://i.ibb.co/vLyFfGC/69039d8d-f98f-478b-8868-727f7792b1c8.jpg",
                "https://i.ibb.co/SQwfhSv/a51c06b8-43b7-4564-904a-c5eef27e1b10.jpg",
                "https://i.ibb.co/DGMXSh3/adf261f1-7f1b-4e8c-ae0d-c7754801d4d7.jpg"
        ],
        "productPrice" : 1500,
        "productDescription" : "Корзина для фруктов или подарков, напечататнная на 3D принтере из вторичного пластика",
        "productParameters": {
            "weight" : 0.1,
            "height" : 8,
            "width" : 22,
            "depth": 10,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#2DBE64",
            "#D7144B"
        ]
    },
    {
        "id": 3,
        "priority" : 51,
        "productName": "Стул",
        "companyName": "TrashBack",
        "companyLogo": "https://play-lh.googleusercontent.com/rotgXqsv6k8LQabVgQgSH6k4XY7Atk_Cx7iOPKF6S4DU1mBi4ibY28FN_InzsmSLLvA=w600-h300-pc0xffffff-pd",
        "images": [
            "https://i.ibb.co/svRwr5Z/stul.png",
            "https://i.ibb.co/mCmk90d/68aba1e5-52d2-4adb-b54e-81585cf1a29a.jpg",
            "https://i.ibb.co/b5gP5Ch/f152ddb2-3126-458f-9c23-0c4dc74efc2d.jpg"
        ],
        "productPrice" : 24000,
        "productDescription" : "Стул напечататнный на 3D принтере из вторичного пластика",
        "productParameters": {
            "weight" : 15,
            "height" : 85,
            "width" : 43,
            "depth": 40,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#2DBE64",
            "#D7144B"
        ]
    },
    {
        "id": 6,
        "priority" : 51,
        "productName": "Кофейный стол",
        "companyName": "TrashBack",
        "companyLogo": "https://play-lh.googleusercontent.com/rotgXqsv6k8LQabVgQgSH6k4XY7Atk_Cx7iOPKF6S4DU1mBi4ibY28FN_InzsmSLLvA=w600-h300-pc0xffffff-pd",
        "images": [
            "https://i.ibb.co/8PThYVH/photo-5325980565297546377-y.jpg",
            "https://i.ibb.co/wMDpKJx/photo-5325980565297546390-y.jpg",
            "https://i.ibb.co/zHnJy71/photo-5325980565297546401-y.jpg",
            "https://i.ibb.co/28rsXqv/photo-5325980565297546405-y.jpg",
            "https://i.ibb.co/zRW1Y76/photo-5325980565297546407-y.jpg",
            "https://i.ibb.co/SmBG7Gp/photo-5325980565297546426-y.jpg" 
        ],
        "productPrice" : 19000,
        "productDescription" : "Кофейный стол, напечатанный на 3D принтере из вторичного пластика",
        "productParameters": {
            "weight" : 8,
            "height" : 45,
            "width" : 40,
            "depth": 40,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#2DBE64",
            "#D7144B"
        ]
    },
    {
        "id": 7,
        "priority" : 51,
        "productName": "Ваза настольная",
        "companyName": "TrashBack",
        "companyLogo": "https://play-lh.googleusercontent.com/rotgXqsv6k8LQabVgQgSH6k4XY7Atk_Cx7iOPKF6S4DU1mBi4ibY28FN_InzsmSLLvA=w600-h300-pc0xffffff-pd",
        "images": [
            "https://i.ibb.co/RQkzhyC/photo-5242381311803508287-y-1.jpg",
            "https://i.ibb.co/TmsTm03/photo-5242381311803508295-y.jpg",
            "https://i.ibb.co/xg4Spb4/photo-5242381311803508284-y.jpg",  
        ],
        "productPrice" : 4000,
        "productDescription" : "Ваза настольная, корпус которой напечататн на 3D принтере из вторичного пластика",
        "productParameters": {
            "weight" : 1,
            "height" : 30,
            "width" : 20,
            "depth": 20,
            "material" : "06-PS",
            "recyclableMaterials" : 70
        },
        "colors":[
            "#2DBE64",
            "#D7144B"
        ]
    },
    {
        "id": 8,
        "priority" : 150,
        "productName": "Стyл детский",
        "companyName": "TrashBack",
        "companyLogo": "https://play-lh.googleusercontent.com/rotgXqsv6k8LQabVgQgSH6k4XY7Atk_Cx7iOPKF6S4DU1mBi4ibY28FN_InzsmSLLvA=w600-h300-pc0xffffff-pd",
        "images": [
            "https://i.ibb.co/6wcpv9V/photo-5325980565297546303-y.jpg",
            "https://i.ibb.co/hFV27JH/photo-5325980565297546305-y.jpg",
            "https://i.ibb.co/RS2DncP/photo-5325980565297546308-y.jpg",
            "https://i.ibb.co/SywwGxw/photo-5325980565297546309-y.jpg",
        ],
        "productPrice" : 19000,
        "productDescription" : "Детский стул, напечатанный на 3D принтере из вторичного пластика",
        "productParameters": {
            "weight" : 10,
            "height" : 80,
            "width" : 40,
            "depth": 40,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#2DBE64",
            "#D7144B"
        ]
    },
]