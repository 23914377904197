import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Shop } from './screens/Shop.screen';
import { Cart } from './screens/Cart.screen';
import { Product } from './screens/Product.screen';
import { useTranslation } from 'react-i18next';
import {
  mainColor,
  mainDarkColor,
  reo1Color,
  reo2Color,
  blackColor,
  yellowColor,
  greyColor,
  greenColor,
  lightGreen,
  lightBlue,
  blueColor,
  pinkColor,
  backgroundColor,
  textColor} from './Constants';
import i18n from './i18n'
const initI18n = i18n;


const theme = createTheme({
  typography: {
    fontFamily: [
      //'Manrope',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      color: textColor
    },
    h2: {
      color: textColor
    },
    h3: {
      color: textColor
    },
    h4: {
      color: textColor
    },
    h5: {
      color: textColor
    },
    h6: {
      color: textColor
    },
    subtitle: {
      color: textColor
    },
    subtitle: {
      color: textColor
    },
    body1: {
      color: textColor
    },
    body2: {
      color: textColor
    },
  },
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      contrastText: '#000',
    },
    reoGreen: {
      light: lightGreen,
      main: reo1Color,
      contrastText: '#000',
    },
    reoBlue: {
      light: lightBlue,
      main: blueColor,
      contrastText: '#fff',
    },
    background: {
      default: "black"
    }
  },
});

export default function App() {
  const {t, i18n} = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Shop />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product" element={<Product />} />
      </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}